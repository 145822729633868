import { obtenirValeur } from '../utilitaires/localStorageUtil';
import { CONSTANTS } from '../config/constants';
import validator from 'validator';

export function obtenir_tous_les_evenements(state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/obtenir/evenement", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            ...state,
            listeEvenement: res.contenu
          })
          return;
        }
        setState({
          ...state,
          listeEvenement: []
        })
      }, 
      (err) => {
        console.log(err)
        setState({
          ...state,
          listeEvenement: []
        })
      }
    )
}

export function obtenir_tous_les_evenements_par_categorie(categorie_uuid, state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/obtenir-evenement/categorie/" + categorie_uuid, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            ...state,
            listeEvenement: res.contenu
          })
          return;
        }
        setState({
          ...state,
          listeEvenement: []
        })
      }, 
      (err) => {
        console.log(err)
        setState({
          ...state,
          listeEvenement: []
        })
      }
    )
}

export function obtenir_evenement(evenement_id, state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/obtenir-evenement/" + evenement_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            ...state,
            evenement: res.contenu
          })
          return;
        }
        setState({
          ...state,
          evenement: []
        })
      },
      (err) => {
        console.log(err)
        setState({
          ...state,
          evenement: []
        })
      }
    )
}

export function ajouter_evenement(state, setState, authContext)
{    
    if(validator.isEmpty(state.evenement.nom ?? ""))
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez remplir le nom !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(state.evenement.adresse ?? ""))
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez remplir l'adresse !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(String(state.evenement.date_depart ?? "")))
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez remplir la date/heure de départ !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(state.evenement.date_fin && !validator.isAfter(String(state.evenement.date_fin), String(state.evenement.date_depart)))
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez remplir mettre la date de fin après la date de début !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(state.evenement.description ?? ""))
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez remplir la description !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(state.evenement.categories.length === 0)
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez choisir une catégorie !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if((!state.evenement.fichier_image || !state.evenement.fichier_image.name || validator.isEmpty(state.evenement.fichier_image.name)) && state.evenement.fichier_image_id == null)
    {
      setState({
        ...state,
        alertFormAjoutEvenement: {
          type: "warning",
          texte: "Veuillez sélectionner une image !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }

    fetch(CONSTANTS.nomDomaineApi + "/ajouter/evenement", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + authContext.authData.token
      },
      body: JSON.stringify({
        ...state.evenement,
        fichier_image: null,
        fichier_image_id: null
      }),
      method: "POST",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.contenu)
          {
            var formData = new FormData();
            formData.append("fichier_image", state.evenement.fichier_image);
            formData.append("fichier_image_id", state.evenement.fichier_image_id);
            formData.append("evenement_id", res.contenu.id);

            fetch(CONSTANTS.nomDomaineApi + "/ajouter/image/evenement", {
                headers: {
                  'Authorization':'Bearer ' + authContext.authData.token
                },
                body: formData,
                method: "POST",
                redirect: 'follow'
              })
                .then(
                  (res) => {
                      if(res.status === 200)
                      {
                          setState({
                              ...state,
                              alertFormAjoutEvenement: {
                                type: "success",
                                texte: "L'événement et son image ont été ajoutés !"
                              },
                              evenement: {
                                  nom: "",
                                  date_depart: "",
                                  date_fin: "",
                                  description: "",
                                  categories: [],
                                  adresse: "",
                                  fichier_image: null,
                                  fichier_image_id: null
                              }
                          })
                          window.scrollTo(0, 0)
                      }
                      else
                      {
                        setState({
                          ...state,
                          alertFormAjoutEvenement: {
                            type: "warning",
                            texte: "L'événement a bel et bien été ajouté, mais l'image n'a pas été téléversé !"
                          },
                          evenement: {
                              nom: "",
                              date_depart: "",
                              date_fin: "",
                              description: "",
                              categories: [],
                              adresse: "",
                              fichier_image: null,
                              fichier_image_id: null
                          }
                        })
                        window.scrollTo(0, 0)
                      }
                  },
                  (err) => {
                      console.log(err)
                      setState({
                          ...state,
                          alertFormAjoutEvenement: {
                            type: "danger",
                            texte: "L'événement n'a pas été ajouté !"
                          }
                      })
                      window.scrollTo(0, 0)
                  }
                )
          }
          else
          {
            setState({
              ...state,
              alertFormAjoutEvenement: {
                type: "danger",
                texte: "L'événement n'a pas été ajouté !"
              }
            })
            window.scrollTo(0, 0)
          }
        },
        (err) => {
            console.log(err)
            setState({
              ...state,
              alertFormAjoutEvenement: {
                type: "danger",
                texte: "L'événement n'a pas été ajouté !"
              }
            })
            window.scrollTo(0, 0)
        }
      )
}

export function modifier_evenement(event, evenement_id, state, setState)
{
    event.preventDefault();
    
    if(validator.isEmpty(state.evenement.nom))
    {
      setState({
        ...state,
        alertFormModificationEvenement: {
          type:"warning",
          texte:"Veuillez remplir le champ 'nom'."
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(state.evenement.adresse))
    {
      setState({
        ...state,
        alertFormModificationEvenement: {
          type:"warning",
          texte:"Veuillez remplir l'adresse !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(String(state.evenement.date_depart)))
    {
      setState({
        ...state,
        alertFormModificationEvenement: {
          type:"warning",
          texte:"Veuillez remplir la date/heure de départ !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(validator.isEmpty(state.evenement.description))
    {
      setState({
        ...state,
        alertFormModificationEvenement: {
          type:"warning",
          texte:"Veuillez remplir la description !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }
    if(state.evenement.categories.length === 0)
    {
      setState({
        ...state,
        alertFormModificationEvenement: {
          type:"warning",
          texte:"Veuillez choisir une catégorie !"
        }
      })
      window.scrollTo(0, 0)
      return;
    }

    const token = obtenirValeur(CONSTANTS.nomValeurToken);

    fetch(CONSTANTS.nomDomaineApi + "/modifier/evenement/" + evenement_id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      },
      body: JSON.stringify(state.evenement),
      method: "PUT",
      redirect: 'follow'
    })
      .then(() => {
          var formData = new FormData();

          if(state.evenement.nouvel_fichier_image)
          {
            formData.append("fichier_image", state.evenement.nouvel_fichier_image);
            formData.append("evenement_id", evenement_id);

            fetch(CONSTANTS.nomDomaineApi + "/ajouter/image/evenement", {
              headers: {
                'Authorization':'Bearer ' + token
              },
              body: formData,
              method: "POST",
              redirect: 'follow'
            })
            .then((res) => {
              if(res.status === 200)
              {
                setState({
                  ...state,
                  alertFormModificationEvenement: {
                    type:"success",
                    texte:"L'événement a été modifié et la nouvelle image lui a été associé !"
                  }
                })
                window.scrollTo(0, 0)
                return;
              }

              setState({
                ...state,
                alertFormModificationEvenement: {
                  type:"warning",
                  texte:"L'événement a été modifié, mais une erreur est survenue lors de l'association de l'image !"
                }
              })
              window.scrollTo(0, 0)
            })
            .catch((err) => {
              console.log(err)
              setState({
                ...state,
                alertFormModificationEvenement: {
                  type:"warning",
                  texte:"L'événement a été modifié, mais une erreur est survenue lors de l'association de l'image !"
                }
              })
              window.scrollTo(0, 0)
            })
          }
          if(state.evenement.fichier_image_id)
          {
            formData.append("image_id", state.evenement.fichier_image_id);
            formData.append("evenement_id", evenement_id);

            fetch(CONSTANTS.nomDomaineApi + "/associer/image/evenement", {
              headers: {
                'Authorization':'Bearer ' + token
              },
              body: formData,
              method: "POST",
              redirect: 'follow'
            })
              .then((res) => {
                if(res.status === 200)
                {
                  setState({
                    ...state,
                    alertFormModificationEvenement: {
                      type:"success",
                      texte:"L'événement a été modifié et la nouvelle image lui a été associé !"
                    }
                  })
                  window.scrollTo(0, 0)
                  return;
                }

                setState({
                  ...state,
                  alertFormModificationEvenement: {
                    type:"warning",
                    texte:"L'événement a été modifié, mais une erreur est survenue lors de l'association de l'image !"
                  }
                })
                window.scrollTo(0, 0)
              })
              .catch((err) => {
                console.log(err)
                setState({
                  ...state,
                  alertFormModificationEvenement: {
                    type:"warning",
                    texte:"L'événement a été modifié, mais une erreur est survenue lors de l'association de l'image !"
                  }
                })
                window.scrollTo(0, 0)
              })
          }

          setState({
            ...state,
            alertFormModificationEvenement: {
              type:"success",
              texte:"L'événement a été modifié sans modifier son image !"
            }
          })
          window.scrollTo(0, 0)
          return;
      })
      .catch((err) => {
        console.log(err)
        setState({
          ...state,
          alertFormModificationEvenement: {
            type:"danger",
            texte:"L'événement n'a pas été modifié !"
          }
        })
        window.scrollTo(0, 0)
      })
}

export function supprimerEvenement(event, evenement_id, state, setState)
{
  event.preventDefault();
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/supprimer/evenement/" + evenement_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "DELETE",
    redirect: 'follow'
  })
    .then(
      (res) => {
          if(res.status === 200)
          {
            setState({
              ...state,
              listeEvenement: null
            })
          }
      },
      (error) => {
        console.log(error)
      }
    )
}

export function supprimer_evenement_fiche_utilisateur_avec_id(evenement_id, state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/supprimer/evenement/" + evenement_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "DELETE",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
          if(res.contenu)
          {
            setState({
              ...state,
              evenement: []
            })
          }
          else
          {
            console.log(res)
          }
      },
      (error) => {
        console.log(error)
      }
    )
}
import React, { useContext } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconeChargement from '../composants/commun/icone-chargement';
import FormulaireModifierCategorieImage from '../composants/modifier-une-categorie-image/formulaire-modifier-categorie-image';

export default function PageModifierCategorieImage() {
  const [state] = useState({
    meta: {
      title: 'Gestion Emotions | Modifier une catégorie'
    }
  });
  const authContext = useContext();

  return (
    <DocumentMeta {...state.meta}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page="Modifier la catégorie d'image"></TitrePage>
          {
            !authContext.authData.estConnecte &&
            <IconeChargement />
          }
          {
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            <FormulaireModifierCategorieImage />   
          }
          <PiedPage></PiedPage>
        </Container>
      </LocalizationProvider>
    </DocumentMeta>
  );
}
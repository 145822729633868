import React, { useContext } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormulaireModifierEvenement from '../composants/modifier-un-evenement/formulaire-modifier-evenement';
import IconeChargement from '../composants/commun/icone-chargement';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/auth-context';

export default function PageModifierEvenement() {
  const [state] = useState({
    meta: {
      title: 'Gestion Emotions | Modifier un événement'
    }
  });
  const params = useParams();
  const authContext = useContext(AuthContext);

  return (
    <DocumentMeta {...state.meta}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page={"Modifier l'événement #" + params.evenement_id}></TitrePage>
          {
            !authContext.authData.estConnecte &&
            <IconeChargement />
          }
          {
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            <FormulaireModifierEvenement/>   
          }
          <PiedPage></PiedPage>
        </Container>
      </LocalizationProvider>
    </DocumentMeta>
  );
}
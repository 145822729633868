import React, { useContext } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import IconeChargement from '../composants/commun/icone-chargement';
import ListeCategorieEvenement from '../composants/liste-categorie-evenement/liste-categorie-evenement';
import { AuthContext } from '../contexts/auth-context';

export default function PageVoirToutesLesCategoriesEvenement() {
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Voir tous les catégories d\'événements'
      }
    });
    const authContext = useContext(AuthContext);

    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          {
            !authContext.authData.estConnecte &&
            <>
              <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
              <IconeChargement/>
            </>
          }
          {
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            <>
              <TitrePage titre_page="Voir tous les catégories d'événements"></TitrePage>
              <ListeCategorieEvenement/>
            </>
          }
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }
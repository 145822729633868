import { CONSTANTS } from "../config/constants"
import { obtenirValeur } from "../utilitaires/localStorageUtil"

export function rechercherEmplacement(adresse, state, setState)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);

  fetch(CONSTANTS.nomDomaineApi + "/google/rechercher-adresse", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "POST",
    body: JSON.stringify({
      "adresse": adresse
    })
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          let contenu = res.contenu
          let nouvelListeResultatMap = contenu.map((adresse) => {
              return {
                  valeur: adresse.formatted_address
              }
          })
          setState({
              ...state,
              listeResultatMap: nouvelListeResultatMap
          })
        }
      },
      (err) => {
        console.log(err)
      }
    )
}
export const CONSTANTS = {
    nomDomaineApi:"https://api.appliemotions.com",
    nomUrlImageApi: "https://api.appliemotions.com/images/evenement/",
    cleGoogleApi: "AIzaSyAJqmhTbIoTjbcdMJSn_6_vrpwEAdq4wu4",
    tempsRequisAvantVerificationCredentiel: 1000,
    nomValeurProfil:"profil",
    nomValeurToken:"token",
    nombreTentativeConnexionMax: 5,
    nomValeurTentativeConnexion: "nombreConnexionTente",
    roles: {
        superAdministrateur:1,
        administrateur:2,
        utilisateur:3
    }
}
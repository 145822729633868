import React, { useContext } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import Navigation from '../composants/commun/navigation';
import CarteEvenement from '../composants/tableau-de-bord/carte-evenement';
import TableauStatistiques from '../composants/tableau-de-bord/tableau-statistiques';
import PiedPage from '../composants/commun/pied-page';
import { AuthContext } from '../contexts/auth-context';
import IconeChargement from '../composants/commun/icone-chargement';

export default function PageTableauDeBord() {
  const [state] = useState({
    meta: {
      title: 'Gestion Emotions | Tableau de bord'
    } 
  });
  const authContext = useContext(AuthContext);

  return (
    <DocumentMeta {...state.meta}>
      <Navigation />
      {
        (
          !authContext.authData.estConnecte ||
          (
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            authContext.authData.profil.role_id > 2
          ) 
        )
        &&
        <>
          <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
          <IconeChargement />
        </>
      }
      {
        authContext.authData.estConnecte &&
        authContext.authData.profil &&
        authContext.authData.profil.role_id <= 2 &&
        <>
          <TitrePage titre_page="Tableau de bord"></TitrePage>
          <TableauStatistiques></TableauStatistiques>
          <CarteEvenement></CarteEvenement>
        </>
      }
      <PiedPage />
    </DocumentMeta>
  );
}
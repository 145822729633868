import { obtenirValeur, sauvegarderValeur, supprimerValeur } from "../utilitaires/localStorageUtil";
import validator from 'validator';
import { CONSTANTS } from "../config/constants";

export function connexion(state, setState, authContext, navigate)
{
    if(validator.isEmpty(state.identifiantsConnexion.courriel))
    {
        setState({
          ...state,
          erreurConnexion: {
            type:"warning",
            texte:"Veuillez entrer un courriel !"
          }
        })
        return;
    }
    if(!validator.isEmail(state.identifiantsConnexion.courriel))
    {
        setState({
          ...state,
          erreurConnexion: {
            type:"warning",
            texte:"Veuillez mettre le courriel en format johndoe@havefunquebec.com !"
          }
        })
        return;
    }
    if(validator.isEmpty(state.identifiantsConnexion.mot_de_passe))
    {
      setState({
        ...state,
        erreurConnexion: {
          type:"warning",
          texte:"Veuillez remplir le mot de passe !"
        }
      })
      return;
    }

    fetch(CONSTANTS.nomDomaineApi + "/se-connecter", {
      headers: {
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(state.identifiantsConnexion),
      redirect: 'follow'
    })
      .then(
        async (responseConnexion) => {
          let responseConnexionJSON = await responseConnexion.json();
          if(responseConnexion.status === 200)
          {
            authContext.setAuth({
              ...authContext.authData,
              token: responseConnexionJSON.contenu.token,
              estConnecte: true
            })
            navigate("/")
          }
          else
          {
            setState({
              ...state,
              erreurConnexion: {
                type:"danger",
                texte: responseConnexionJSON.message
              }
            })
          }
        },
        (error) => {
          console.log(error)
          setState({
            ...state,
            erreurConnexion: {
              type: "danger",
              texte: "Une erreur inattendue est survenue !"
            }
          })
        }
      )
}

export function verifier_token(authData, disconnect, returnToHomePage, location)
{
  fetch(CONSTANTS.nomDomaineApi + "/verifier-token", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + authData.token
    }
  })
    .then(async (response) => {
        if(response.status === 200 && authData.profil && authData.profil.role_id <= 2)
        {
          if(location.pathname === "/connexion" || location.pathname === "/mot-de-passe-oublier") returnToHomePage()
        }
        else if(response.status === 200 && authData.profil && authData.profil.role_id > 2)
        {
          disconnect();
        }
        else if(response.status === 403)
        {
          disconnect();
        }
        else if(response.status === 503 || response.status === 500)
        {
          let nombreConnexion = obtenirValeur("nombre_connexion")
          if(!nombreConnexion)
          {

            sauvegarderValeur("nombre_connexion", 1)
          }
          if(nombreConnexion && nombreConnexion < 5)
          {
            sauvegarderValeur("nombre_connexion", nombreConnexion + 1)
          }
          if(nombreConnexion && nombreConnexion > 5)
          {
            supprimerValeur("nombre_connexion")
            disconnect();
          }
        }
      }
    )
    .catch((error) => {
      console.log(error)
      let nombreConnexion = obtenirValeur("nombre_connexion")
      if(!nombreConnexion)
      {
        sauvegarderValeur("nombre_connexion", 1)
      }
      if(nombreConnexion && nombreConnexion < 5)
      {
        sauvegarderValeur("nombre_connexion", nombreConnexion + 1)
      }
      if(nombreConnexion && nombreConnexion > 5)
      {
        supprimerValeur("nombre_connexion")
        disconnect();
      }
    })
}

export function envoyer_courriel_reinitialisation(event, state, setState)
{
  event.preventDefault();

  if(validator.isEmpty(state.courriel))
  {
    setState({
      ...state,
      erreurReinitialisationMotDePasse:{
        type:"warning",
        texte:"Veuillez remplir le courriel !"
      }
    })
    return;
  }
  if(!validator.isEmail(state.courriel))
  {
    setState({
      ...state,
      erreurReinitialisationMotDePasse:{
        type:"warning",
        texte:"Veuillez remplir le courriel avec la forme suivante 'johndoe@exemple.com' !"
      }
    })
    return;
  }

  fetch(CONSTANTS.nomDomaineApi + "/courriel/reinitalisation", {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      courriel: state.courriel
    }),
    method: "POST",
    redirect: 'follow'
  })
    .then(
      async (response) => {
        let responseJSON = await response.json();
        if(response.status === 200)
        {
          setState({
            ...state,
            courrielReinitialisationEnvoye: true,
            erreurReinitialisationMotDePasse: {
              type:"success",
              texte:"Veuillez entrer le code à 6 chiffres qui a été envoyé à votre adresse courriel."
            },
          })
        }
        else
        {
          setState({
            ...state,
            erreurReinitialisationMotDePasse:{
              type:"warning",
              texte: responseJSON.message
            }
          })
        }
      }, 
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurReinitialisationMotDePasse:{
            type:"warning",
            texte:"Une erreur inattendue a empêché d'envoyer le courriel de réinitialisation !"
          }
        })
      }
    )
}

export function envoyer_code_reinitialisation(event, state, setState)
{
  event.preventDefault();

  if(validator.isEmpty(state.code))
  {
    setState({
      ...state,
      erreurReinitialisationMotDePasse:{
        type:"warning",
        texte:"Veuillez entrer le code de réinitialisation !"
      }
    })
    return;
  }
  if(!validator.isLength(state.code, { min: 5}))
  {
    setState({
      ...state,
      erreurReinitialisationMotDePasse:{
        type:"warning",
        texte:"Veuillez entrer le code de réinitialisation d'une longueur de 5 chiffres !"
      }
    })
    return;
  }

  fetch(CONSTANTS.nomDomaineApi + "/verifier-code-reinitialisation", {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      courriel: state.courriel,
      code_renitialisation: state.code
    }),
    method: "POST",
    redirect: 'follow'
  })
    .then(
      async (response) => {
        let responseJSON = await response.json();
        if(response.status === 200)
        {
          setState({
            ...state,
            codeReinitialisationEnvoye: true,
            erreurReinitialisationMotDePasse: {
              type:"success",
              texte:"Veuillez entrer votre nouveau mot de passe."
            },
          })
        }
        else
        {
          setState({
            ...state,
            erreurReinitialisationMotDePasse:{
              type:"warning",
              texte: responseJSON.message
            }
          })
        }
      }, 
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurReinitialisationMotDePasse:{
            type:"warning",
            texte:"Une erreur inattendue a empêché d'envoyer le courriel de réinitialisation !"
          }
        })
      }
    )
}

export function changer_mon_mot_de_passe(event, state, setState)
{
    event.preventDefault();

    if(validator.isEmpty(state.courriel))
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Le courriel n'est plus retrouvable, veuillez recommencer le processus de réinitialisation de mot de passe !"
        }
      })
      return;
    }
    if(validator.isEmpty(state.code))
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Le code n'est plus retrouvable, veuillez recommencer le processus de réinitialisation de mot de passe !"
        }
      })
      return;
    }
    if(!validator.isLength(state.code, { min: 5}))
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Le code n'est plus valide, veuillez recommencer le processus de réinitialisation de mot de passe !"
        }
      })
      return;
    }
    if(validator.isEmpty(state.motDePasse))
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Veuillez remplir le mot de passe !"
        }
      })
      return;
    }
    if(validator.isEmpty(state.motDePasseRepeter))
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Veuillez remplir le mot de passe deux fois !"
        }
      })
      return;
    }
    if(state.motDePasseRepeter !== state.motDePasse)
    {
      setState({
        ...state,
        erreurReinitialisationMotDePasse:{
          type:"warning",
          texte:"Veuillez remplir les deux mot de passes afin qu'il soit identique !"
        }
      })
      return;
    }

    fetch(CONSTANTS.nomDomaineApi + "/changer-mon-mot-de-passe", {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        courriel: state.courriel,
        code_renitialisation: state.code,
        mot_de_passe: state.motDePasse
      }),
      method: "POST",
      redirect: 'follow'
    })
      .then(
        async (response) => {
          let responseJSON = await response.json();
          if(response.status === 200)
          {
            setState({
              ...state,
              motDePasseModifie: true,
              erreurReinitialisationMotDePasse: {
                type: "success",
                texte: responseJSON.message
              },
            })
          }
          else
          {
            setState({
              ...state,
              erreurReinitialisationMotDePasse:{
                type:"warning",
                texte: responseJSON.message
              }
            })
          }
        }, 
        (error) => {
          console.log(error)
          setState({
            ...state,
            erreurReinitialisationMotDePasse:{
              type:"warning",
              texte: "Une erreur est survenue lors du changement de votre mot de passe !"
            }
          })
        }
      )
}
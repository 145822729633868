import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import { modifier_image, supprimer_image } from '../../services/imageService';
import HFAlerte from '../commun/hf-alerte';
import styles from './fenetre-image.module.css';

export default function FenetreImage(props) {

    const [state, setState] = useState({
        erreurModificationImage:{
            type:"",
            valeur:""
        },
        image: {
            nom: "",
            description: "",
            categories: []
        }
    })
    
    function modifier_liste_categories_image(event)
    {
        if(event.target.checked)
        {
            state.image.categories.push(props.listeImageCategorie.find((categorie) => {
                return String(categorie.id) === event.target.value
            }))
            setState({
                ...state,
                erreurModificationImage: {
                    type: "",
                    texte: ""
                },
            })
        }
        else
        {
            let nouvelle_liste_categorie = state.image.categories

            if(nouvelle_liste_categorie.length > 1)
            {
                nouvelle_liste_categorie = nouvelle_liste_categorie.filter((categorie) => {
                    return String(categorie.id) !== event.target.value
                })
                setState({
                    ...state,
                    image: {
                        ...state.image,
                        categories: nouvelle_liste_categorie
                    }
                })
                return;
            }

            setState({
                ...state,
                image: {
                    ...state.image,
                    categories: []
                }
            })
        }
    }

    function verifierSiClique(liste_categories_image, categorie_id)
    {
        let trouve = false;
        liste_categories_image.map((categorie) => {
            if(categorie.id === categorie_id) trouve = true;
            return null;
        })
        return trouve;
    }

    console.log(props)

    return (
        <>
            {
                props.show &&
                <Modal 
                    show={props.show} 
                    onHide={() => {
                        setState({
                            ...state,
                            image: {
                                nom: "",
                                description:"",
                                categories: []
                            },
                            erreurModificationImage: {
                                type: "",
                                valeur: ""
                            }
                        })
                        props.handleClose()
                    }}
                    dialogClassName={styles.fenetre_modal}
                >
                    <Modal.Header closeButton>
                        {
                            props.imageActuel &&
                            <Modal.Title className={styles.nom_image}>Image: {state.image.nom !== '' ? state.image.nom : props.imageActuel.nom ?? "Aucun nom"}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        {
                            props.imageActuel &&
                            <Row>
                                <Col lg="9">
                                    <Row className={styles.fenetre_section_image}>
                                        <img 
                                            className={styles.image_fenetre + " img-responsive"}
                                            src={CONSTANTS.nomUrlImageApi + props.imageActuel.fichier_image}
                                            alt={props.imageActuel.description}
                                        />
                                    </Row>
                                    <Row className={styles.fenetre_section_table}>
                                        <Col>
                                            <Table variant='dark' bordered hover>
                                                <tbody>
                                                    <tr>
                                                        <td className='col-md-5'>Nom actuel</td>
                                                        <td className='col-md-7'>{state.image.nom !== '' ? state.image.nom : props.imageActuel.nom ?? "Aucun nom"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-md-5'>Description</td>
                                                        <td className='col-md-5'>{state.image.description !== '' ? state.image.description : props.imageActuel.description ?? "Aucune description"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-md-5'>Date de création</td>
                                                        <td className='col-md-5'>{props.imageActuel.date_creation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-md-5'>Date de la dernière modification</td>
                                                        <td className='col-md-5'>{props.imageActuel.date_modification}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="3">
                                    <Form 
                                        className={styles.form_modification_image}
                                        noValidate
                                    >
                                        {
                                            state.erreurModificationImage.valeur &&
                                            <HFAlerte
                                                aucunRow={true}
                                                type={state.erreurModificationImage.type}
                                                texte={state.erreurModificationImage.valeur}
                                            />
                                        }
                                        <Form.Group className="mb-3" controlId="hf_courriel">
                                            <Form.Label className={styles.fenetre_label_image}>Nouveau nom de l'image*</Form.Label>
                                            <Form.Control 
                                                onChange={(event) => { 
                                                    setState({
                                                        ...state,
                                                        erreurModificationImage:{
                                                            type:"",
                                                            valeur:""
                                                        },
                                                        image: {
                                                            ...state.image,
                                                            nom: event.target.value
                                                        }
                                                    })
                                                }} 
                                                type="text" 
                                                placeholder="Scène du Festival de Québec" 
                                                autoFocus={true}
                                                value={state.image.nom}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="hf_description_evenement">
                                            <Form.Label className={styles.fenetre_label_image}>Nouvelle description de l'image*</Form.Label>
                                            <Form.Control
                                                placeholder="ex: Gros plan du spectacle d'Eminem et de la foule"
                                                onChange={
                                                    (event) => 
                                                    { 
                                                        setState({
                                                            ...state,
                                                            erreurModificationImage:{
                                                                type:"",
                                                                valeur:""
                                                            },
                                                            image : {
                                                                ...state.image,
                                                                description: event.target.value
                                                            }
                                                        })
                                                    }
                                                }
                                                value={state.image.description} 
                                                as="textarea" 
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className={styles.fenetre_label_image}>Catégorie de l'image*</Form.Label>
                                            {
                                                props.listeImageCategorie 
                                                && props.listeImageCategorie.map((categorie) => (
                                                <div key={`default-checkbox-${categorie.id}`}>
                                                    <Form.Check>
                                                        <Form.Check.Input
                                                            onClick={(event) => {
                                                                modifier_liste_categories_image(event)
                                                            }}
                                                            type="checkbox"
                                                            value={categorie.id}
                                                            defaultChecked={verifierSiClique(props.imageActuel.categories, categorie.id)}
                                                            id={`default-${categorie.id}`} 
                                                        />
                                                        <Form.Check.Label className={styles.fenetre_label_categorie}>{categorie.nom}</Form.Check.Label>
                                                    </Form.Check>
                                                </div>
                                            ))}
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={(event) => {
                            supprimer_image(event, props.imageActuel.id)
                            props.supprimerImageListe(props.imageActuel.id)
                            props.handleClose()
                            setState({
                                ...state,
                                image:{
                                    nom: "",
                                    description:"",
                                    categories: []
                                },
                                erreurModificationImage:{
                                    type:"",
                                    valeur:""
                                }
                            })
                        }}>Supprimer l'image</Button>
                        <Button variant="success" onClick={(event) => {
                            event.preventDefault();
                            props.modifier_image_liste(
                                {
                                    id: props.imageActuel.id,
                                    ...props.imageActuel,
                                    ...state.image,
                                }
                            )
                            modifier_image(state, setState, props.imageActuel.id)
                        }}
                        >Confirmer changement</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
  }
import React from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import FormulaireMotDePasseOublier from '../composants/mot-de-passe-oublier/formulaire-mot-de-passe-oublier';

export default function PageMotDePasseOublier() {
  const [state] = useState({
    meta: {
      title: 'Gestion Emotions | Réinitialiser son mot de passe'
    }
  });

  return (
    <DocumentMeta {...state.meta}>
      <Container fluid>
        <Navigation></Navigation>
        <TitrePage titre_page="Réinitialisation du mot de passe"></TitrePage>
        <FormulaireMotDePasseOublier></FormulaireMotDePasseOublier>
        <PiedPage></PiedPage>
      </Container>
    </DocumentMeta>
  );
}
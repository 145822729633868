import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './fiche-evenement.module.css';
import { Badge, Button, Table } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { obtenir_evenement, supprimer_evenement_fiche_utilisateur_avec_id } from '../../services/evenementService';
import { creerCleUnique } from '../../utilitaires/listUtil';
import HFAlerte from '../commun/hf-alerte';

export default function FicheEvenement() {

    const [state, setState] = useState({
        evenement: null
    })
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        !state.evenement && obtenir_evenement(params.evenement_id, state, setState)
    }, [state, params.evenement_id])

    return (
        <>
            {
                !params.evenement_id &&
                <HFAlerte 
                    type="warning"
                    texte="L'identifiant fournie n'est pas reconnue."
                />
            }
            {
                state.evenement &&
                state.evenement.length === 0 &&
                <Row>
                    <Col lg="4"></Col>
                    <Col lg="4" className='text-center'>
                        <Button
                            onClick={() => {
                                navigate('/voir-tous-les-evenements')
                            }}
                            className={styles.bouton_retour_evenement}
                        >Retourner aux événements</Button>
                        <HFAlerte 
                            type="warning"
                            texte="L'événement n'existe pas."
                            aucunRow
                        />
                    </Col>
                    <Col lg="4"></Col>
                </Row>
            }
            {
                state.evenement &&
                state.evenement.length !== 0 &&
                params.evenement_id &&
                <>
                    <Row className={styles.conteneur_action + " text-center"}>
                        <Col lg={4} xs="1"></Col>
                        <Col lg={4} xs="10">
                            <h1 className={styles.titre_evenement}>{state.evenement.nom}</h1>
                            {
                                state.evenement.image_evenement &&
                                <img 
                                    className='img-fluid'
                                    src={CONSTANTS.nomDomaineApi + state.evenement.fichier_image}
                                    alt={state.evenement.description}
                                />
                            }
                            {
                                state.evenement.fichier_image &&
                                <img 
                                    alt="Représente l'événement."
                                    className={'img-fluid ' + styles.image_evenement}
                                    src={CONSTANTS.nomDomaineApi + "/images/evenement/" + state.evenement.fichier_image}
                                />
                            }
                            <Row className={styles.bordure_action + " text-center"}>
                                <Col sm="12">
                                    <h2 className={styles.titre_actions_evenement}>Actions</h2>
                                </Col>
                                <Col sm="12">
                                    <Link to={"/modifier-evenement/" + state.evenement.id}>
                                        <Button className={styles.hf_bouton_fiche}>Modifier</Button>
                                    </Link>
                                </Col>
                                <Col sm="12">
                                    <Link to={"/supprimer-evenement/" + state.evenement.id}>
                                        <Button 
                                            className={styles.hf_bouton_fiche}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                supprimer_evenement_fiche_utilisateur_avec_id(state.evenement.id, state, setState);
                                                setState({
                                                    evenement: null
                                                })
                                            }}
                                        >Supprimer</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Table variant='dark' striped bordered hover>
                                <tbody>
                                    <tr>
                                        <th>Date de création</th>
                                        <td>{state.evenement.date_creation}</td>
                                    </tr>
                                    <tr>
                                        <th>Date de dernière modification</th>
                                        <td>{state.evenement.date_modification}</td>
                                    </tr>
                                    <tr>
                                        <th>Date de départ</th>
                                        <td>{state.evenement.date_depart}</td>
                                    </tr>
                                    <tr>
                                        <th>Date de fin</th>
                                        <td>{state.evenement.date_fin}</td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td>{state.evenement.description}</td>
                                    </tr>
                                    <tr>
                                        <th>Adresse</th>
                                        <td>{state.evenement.adresse}</td>
                                    </tr>
                                    <tr>
                                        <th>Catégories</th>
                                        <td>
                                            {
                                                state.evenement.categories && state.evenement.categories.map((categorie) => {
                                                    return (
                                                    <LinkContainer key={creerCleUnique()} to={"/voir-tous-les-evenement/categorie/" + categorie.slug}>
                                                        <Badge className={styles.hf_bouton_liste} bg="primary">{categorie.nom}</Badge>
                                                    </LinkContainer>
                                                    )
                                                })
                                            }
                                            {
                                                state.evenement.categories &&
                                                state.evenement.categories.length === 0 &&
                                                "Aucune catégorie associé"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Localisation</th>
                                        <td>
                                            <p><b>Latitude: </b>{state.evenement.latitude}</p>
                                            <p><b>Longitude: </b>{state.evenement.longitude}</p>
                                            <p></p>
                                            <a 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={ 
                                                    "https://www.google.com/maps/search/?api=1&query=" + state.evenement.adresse
                                                }>
                                                <Button>Voir sur Google Maps</Button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                </Table>
                        </Col>
                        <Col lg={4}></Col>
                    </Row>
                </>
            }
        </>
    );
  }
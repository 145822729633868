import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import { obtenir_toutes_les_alertes } from '../../services/alerteService';
import HFAlerte from '../commun/hf-alerte';
import { Col } from 'react-bootstrap';

export default function ListeAlerte() {
  const [state, setState] = useState({
    listeAlerte: null
  });

  useEffect(() => {
      !state.listeAlerte && obtenir_toutes_les_alertes(state, setState); 
  }, [state])
  
  return (
    <Row>
        <Col lg="2"></Col>
        <Col lg="8">
          {
            state.listeAlerte &&
            state.listeAlerte.length === 0 &&
            <HFAlerte variant="danger" texte="Aucune alerte à afficher."/>
          }
      </Col>
      <Col lg="2"></Col>
    </Row>
  );
}
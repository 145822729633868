import { CONSTANTS } from "../config/constants";
import axios from "axios";

export function obtenirProfil(token, state, setState)
{
    axios.get(CONSTANTS.nomDomaineApi + "/obtenir-profil", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':'Bearer ' + token
        }
      })
        .then((response) => {
          if(response.data)
          {
            if(response.data.contenu.image_profil)
            {
              setState({
                ...state,
                lastPulledProfile: {
                  ...response.data.contenu,
                  image_profil: CONSTANTS.nomDomaineApi + "/images/profil/" + response.data.contenu.image_profil,
                }
              })
              return;
            }
            setState({
              ...state,
              lastPulledProfile: {
                ...response.data.contenu,
                image_profil: CONSTANTS.nomDomaineApi + "/images/profil/defaut/image-profil-defaut.png"
              }
            })
            return;
          }
          setState({
            ...state,
            lastPulledProfile: null
          })
        })
        .catch((error) => {
          console.log(error)
          setState({
            ...state,
            lastPulledProfile: null
          })
        })
}

export function telechargerPhotoProfil(authContext, imagePickerData)
{
  const uri = imagePickerData.assets[0].uri;
  const fileName = uri.split('/').pop();
  const fileType = fileName.split('.').pop();
  const formData = new FormData();
  formData.append('fichier_image', { 
    uri, 
    name: fileName, 
    type: `image/${fileType}` 
  });

  axios.post(
    CONSTANTS.nomDomaineApi + "/changer-image-profil", 
    formData,
    {
      headers: {
        'accept': 'application/json',
        'Authorization': 'Bearer ' + authContext.authData.token,
        'Content-Type': "multipart/form-data",
      }
    }
  )
    .then((response) => {
      if(response.status === 200)
      {
        authContext.reloadProfile();
        return;
      }
    })
    .catch((error) => {
        console.log(error)
    })
}
import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './pied-page.module.css';
import packageJSON from '../../../package.json';

function obtenirAnnee()
{
    return new Date().getFullYear();
}

export default function PiedPage() {
    return (
        <Row className={styles.conteneur_pied_page}>
            <Col lg="1" xs="1"></Col>
            <Col lg="10" xs="10" className={"text-center " + styles.fond_pied_page }>
                <p className={styles.texte_copyright}>Emotions App Corp. © {obtenirAnnee()} - Tous droits réservés.</p>
                <p>Site web fait par <a target="_blank" rel="noopener noreferrer" href="https://greenwoodmultimedia.com?utm_source=gestion.appliemotions.com&utm_medium=bas_page&utm_id=lien_bas_page_gestion_emotions">GreenWood Multimedia</a>.</p>
                <p>Version { packageJSON.version }.</p>
            </Col>
            <Col lg="1" xs="1"></Col>
        </Row>
    );
  }
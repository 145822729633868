import React from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import FormulaireConnexion from '../composants/connexion/formulaire-connexion';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';

export default function PageConnexion() {

    //Création des constantes de la page connexion
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Connexion',
        description: 'Page de connexion du site web de gestion de l\'application Emotions.',
        canonical: 'https://gestion.appliemotions.com/connexion'
      }
    });
    
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page="Connexion"></TitrePage>
          <FormulaireConnexion>
          </FormulaireConnexion>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }
import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown, Col, Button } from 'react-bootstrap';
import styles from './navigation.module.css';
import { Link, useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import * as Icon from 'react-bootstrap-icons';
import { AuthContext } from '../../contexts/auth-context';

export default function Navigation() {

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div>
            {
                (
                    !authContext.authData.estConnecte ||
                    (
                      authContext.authData.estConnecte &&
                      authContext.authData.profil &&
                      authContext.authData.profil.role_id > 2
                    ) 
                  )   
                &&
                <Row className={styles.conteneur_navigation_non_connecte}>
                    <Col xs={3}></Col>
                    <Col xs={6} className="text-center">
                        <Link className={styles.hf_titre_logo} to="/">
                            <Row>
                                <span className={styles.hf_sous_titre_logo_icone}>Gestion</span>
                                <span className={styles.hf_titre_logo_icone}>Emotions</span>
                            </Row>
                        </Link>
                    </Col>
                    <Col xs={3}></Col>
                </Row>
            }
            {
                authContext.authData.estConnecte &&
                authContext.authData.profil &&
                authContext.authData.profil.role_id <= 2 &&
                <Row>
                    <Navbar className={styles.conteneur_navigation} collapseOnSelect expand="lg">
                        <Container>
                            <Navbar.Brand href="/">
                                <Row>
                                    <span className={styles.hf_sous_titre_logo_icone}>Gestion</span>
                                    <span className={styles.hf_titre_logo_icone}>Emotions</span>
                                </Row>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <NavDropdown title="Gestion des événements" id="basic-nav-dropdown">
                                        <Link className="dropdown-item" to="/ajouter-evenement">Ajouter un événement</Link>
                                        <Link className="dropdown-item" to="/voir-tous-les-evenements">Voir tous les événements</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/ajouter-categorie-evenement">Ajouter une catégorie d'événement</Link>
                                        <Link className="dropdown-item" to="/voir-toutes-les-categories-evenement">Voir les catégories d'événement</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/ajouter-categorie-image">Ajouter une catégorie d'image</Link>
                                        <Link className="dropdown-item" to="/voir-toutes-les-categories-images">Voir les catégories d'images</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/bibliotheque-image-evenement">Biblothèque des images</Link>
                                    </NavDropdown>
                                    <NavDropdown title="Gestion des utilisateurs" id="basic-nav-dropdown">
                                        <Link className="dropdown-item" to="/ajouter-utilisateur">Ajouter un utilisateur</Link>
                                        <Link className="dropdown-item" to="/voir-tous-les-utilisateurs">Voir tous les utilisateurs</Link>
                                    </NavDropdown>
                                </Nav>
                                <Nav className="justify-content-end">
                                    <Button 
                                        className={"nav-link " + styles.button_disconnect}
                                        onClick={() => {
                                            navigate("/alertes");
                                        }}
                                    >
                                        <Icon.ExclamationCircle className={styles.hf_icone_alerte} size={24} color="grey"/>
                                        Alertes
                                    </Button>
                                    <Button 
                                        onClick={() => {
                                            authContext.disconnect();
                                        }}
                                        className={"nav-link " + styles.button_disconnect}
                                    >
                                        <Icon.BoxArrowInLeft className={styles.hf_icone_deconnexion} size={24} color="grey"/> 
                                        <span className={styles.hf_icone_texte}>Se déconnecter</span>
                                    </Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Row>
            }
        </div>
    );
  }
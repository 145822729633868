import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge, Button, Card, Form, FormGroup, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './liste-categorie-image.module.css';
import { creerCleUnique } from '../../utilitaires/listUtil';
import { obtenir_toutes_les_categories_images, supprimer_categorie_image } from '../../services/categorieImageService';
import HFAlerte from '../commun/hf-alerte';
import { useNavigate } from 'react-router-dom';

function genererListeEvenement(state, setState, width) {

  if(width <= 950)
  {
    return (
      state.listeImageCategorie &&
      state.listeImageCategorie
      .filter((categorie) => {
        if(state.texteRecherche)
        {
          if(categorie.nom && categorie.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          if(categorie.slug && categorie.slug.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          if(categorie.description && categorie.description.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          return false;
        }
        return true;
      })
      .slice(state.indexImageCategorie, state.indexImageCategorie + 10)
      .map((image_categorie) => {
        return (
          <Card className={styles.conteneur_liste_evenement}>
            <Card.Title className={styles.titre_carte_categorie_evenement}>{image_categorie.nom}</Card.Title>
            <Card.Text>{image_categorie.description ?? "N/D"}</Card.Text>
            <LinkContainer 
              className={styles.hf_bouton_liste} 
              to={"/bibliotheque-image-evenement/categorie/" + image_categorie.slug}
            >
                <Badge bg="primary">Voir la catégorie d'événement</Badge>
            </LinkContainer>
            {
              image_categorie.slug !== "non-categorise" &&
              <>
                <LinkContainer className={styles.hf_bouton_liste} to={"/modifier-categorie-image/" + image_categorie.slug}><Badge bg="primary">Modifier la catégorie</Badge></LinkContainer>
                <Badge className={styles.hf_bouton_liste} onClick={(event) => { supprimer_categorie_image(event, state, setState, image_categorie.id) }} bg="primary">Supprimer la catégorie</Badge>
              </>
            }
          </Card>
        )
      })
      
    );
  }

  return (
    <Table className={styles.conteneur_liste_evenement} variant="dark" striped bordered hover size="sm" responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Slug</th>
          <th>Nom de la catégorie</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          state.listeImageCategorie &&
          state.listeImageCategorie
          .filter((categorie) => {
            if(state.texteRecherche)
            {
              if(categorie.nom && categorie.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              if(categorie.slug && categorie.slug.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              if(categorie.description && categorie.description.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              return false;
            }
            return true;
          })
          .slice(state.indexImageCategorie, state.indexImageCategorie + 10)
          .map((categorie) => {
            return (
              <tr key={creerCleUnique()}>
                <td>{categorie.id}</td>
                <td>{categorie.slug}</td>
                <td>{categorie.nom}</td>
                <td>{categorie.description}</td>
                <td>
                  <LinkContainer 
                    className={styles.hf_bouton_liste} 
                    to={"/bibliotheque-image-evenement/categorie/" + categorie.slug}
                  >
                      <Badge bg="primary">Voir la catégorie d'événement</Badge>
                  </LinkContainer>
                  {
                    categorie.slug !== "non-categorise" &&
                    <>
                      <LinkContainer className={styles.hf_bouton_liste} to={"/modifier-categorie-image/" + categorie.slug}><Badge bg="primary">Modifier la catégorie</Badge></LinkContainer>
                      <Badge className={styles.hf_bouton_liste} onClick={(event) => { supprimer_categorie_image(event, state, setState, categorie.id) }} bg="primary">Supprimer la catégorie</Badge>
                    </>
                  }
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  );
}

export default function ListeCategorieImage() {
    const [state, setState] = useState({
      listeImageCategorie: null,
      texteRecherche: "",
      indexImageCategorie: 0
    });
    const width = window.innerWidth;
    const navigate = useNavigate();

    useEffect(() => {
        !state.listeImageCategorie && obtenir_toutes_les_categories_images(state, setState); 
    }, [state])
    
    return (
      <>
        <Row className='text-center'>
          <Col lg="4"></Col>
          <Col lg="4">
            <Button
              onClick={() => {
                navigate("/ajouter-categorie-image")
              }}
              className={styles.bouton_ajout_categorie_image}
            >Ajouter catégorie d'image</Button>
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className={'text-center ' + styles.conteneur_bouton_pagination}>
            <Col lg="1"></Col>
            <Col lg="3" xs="12">
              <Button
                className={styles.bouton_precedent}
                disabled={state.listeImageCategorie && state.indexImageCategorie <= 0}
                onClick={() => {
                  if(state.listeImageCategorie && state.indexImageCategorie > 0){
                    setState({
                      ...state,
                      indexImageCategorie: state.indexImageCategorie - 10
                    })
                  }
                }}
              >Précédent</Button>
            </Col>
            <Col lg="4" xs="12" className="text-center">
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <FormGroup>
                    <input 
                      className='form-control'
                      type='text'
                      placeholder="Rechercher une catégorie d'image"
                      value={state.texteRecherche}
                      onChange={(event) => {
                        setState({
                          ...state,
                          texteRecherche: event.target.value
                        })
                      }}
                    />
                  </FormGroup>
                </Form>
            </Col>
            <Col lg="3" xs="12">
              <Button
                className={styles.bouton_suivant}
                disabled={state.listeImageCategorie && state.indexImageCategorie > state.listeImageCategorie.length - 10}
                onClick={() => {
                  if(state.listeImageCategorie && state.indexImageCategorie < state.listeImageCategorie.length){
                    setState({
                      ...state,
                      indexImageCategorie: state.indexImageCategorie + 10
                    })
                  }
                }}
              >Suivant</Button>
            </Col>
            <Col lg="1" xs="2"></Col>
        </Row>
        <Row>
            <Col xs="1"></Col>
            <Col xs="10" className="text-center">
                {
                    state.listeImageCategorie && genererListeEvenement(state, setState, width)
                }
                {
                    !state.listeImageCategorie && 
                    <HFAlerte  variant='warning' texte="Aucune catégorie d'événement trouvé !" />
                }
            </Col>
            <Col xs="1"></Col>
        </Row>
      </>
    );
  }
import React, { useContext, useEffect, useRef, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { format } from 'date-fns/esm';
import { rechercherEmplacement } from '../../services/googleService';
import styles from './formulaire-ajouter-evenement.module.css';
import { obtenir_toutes_les_images } from '../../services/imageService';
import { CONSTANTS } from '../../config/constants';
import { creerCleUnique } from '../../utilitaires/listUtil';
import { obtenir_toutes_les_categories_evenement } from '../../services/categorieEvenementService';
import HFAlerte from '../commun/hf-alerte';
import { ajouter_evenement } from '../../services/evenementService';
import { obtenir_toutes_les_categories_images } from '../../services/categorieImageService';
import { compareAsc } from 'date-fns';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';

function selectionner_adresse(event, state, setState)
{
    setState({
        ...state,
        evenement: {
            ...state.evenement,
            adresse: event.target.textContent
        },
        listeResultatMap: []
    })
}

function ajouter_categorie(event, state, setState)
{
    if(event.target.checked)
    {
        let ancienne_liste_categorie = state.evenement.categories
        ancienne_liste_categorie.push(state.listeCategories.find((element) => {
            return String(element.id) === event.target.value
        }))
        state.evenement.categories = ancienne_liste_categorie
        setState({
            ...state,
            alertFormAjoutEvenement: {
                type: "",
                texte: ""
            },
        })
    }
    else
    {
        let ancienne_liste_categorie = state.evenement.categories

        if(ancienne_liste_categorie.length > 1)
        {
            ancienne_liste_categorie = ancienne_liste_categorie.filter((categorie) => {
                return categorie.id !== event.target.value
            })
            state.evenement.categories = ancienne_liste_categorie
            setState(state)
            return;
        }

        state.evenement.categories = []
        setState({
            ...state,
            alertFormAjoutEvenement: {
                type: "",
                texte: ""
            },
        })
    }
}

export default function FormulaireAjouterEvenement() {

    const [state, setState] = useState({
        alertFormAjoutEvenement: {
            type: "",
            texte: ""
        },
        evenement: {
            nom: null,
            date_depart: format(new Date("2025-01-01 00:00:00"), "yyyy-MM-dd 00:00:00"),
            date_fin: null,
            description: null,
            categories: [],
            adresse: null,
            lien_billeterie: null,
            fichier_image: null,
            fichier_image_id: null
        },
        fichier_image_evenement: null,
        choix_categorie:null,
        nom_image_rechercher:null,
        listeImageCategorie: null,
        listeImageEvenement:null,
        listeResultatMap: null,
        listeCategories: null,
        imageMin: 0,
        imageMax: 4
    })
    const refInputAjoutImage = useRef();
    const refInputSelectionImage = useRef();
    const refChangementPageImage = useRef();
    const refAlerteSelectionImage = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        !state.listeCategories && obtenir_toutes_les_categories_evenement(state, setState);
    }, [state])

    useEffect(() => {
        state.listeCategories &&
        !state.listeImageEvenement && obtenir_toutes_les_images(state, setState);
    }, [state])

    useEffect(() => {
        state.listeCategories &&
        state.listeImageEvenement &&
        !state.listeImageCategorie && obtenir_toutes_les_categories_images(state, setState);
    }, [state])

    useEffect(() => {
        const handler = setTimeout(() => {
            state.evenement.adresse && rechercherEmplacement(state.evenement.adresse, state, setState);
        }, 500);
    
        // Cleanup function to clear the timeout if the effect re-runs before the delay is completed
        return () => clearTimeout(handler);
    }, [state.evenement.adresse]);

    return (
        <>
            {
                state.alertFormAjoutEvenement.texte &&
                <HFAlerte
                    type={state.alertFormAjoutEvenement.type}
                    texte={state.alertFormAjoutEvenement.texte}
                />
            }
            <Row className={styles.conteneur_form_ajout_evenement}>
                <Col lg="3" xs="1"></Col>
                <Col lg="6" xs="10" className="text-center">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4">
                            <Button
                                onClick={() => {
                                    navigate("/voir-tous-les-evenements")
                                }}
                            >Retourner aux événements</Button>
                        </Col>
                        <Col lg="4"></Col>
                    </Row>
                    {
                        state.fichier_image_evenement &&
                        <img className={'img-fluid ' + styles.image_evenement} src={state.fichier_image_evenement} alt="Représente l'événement à ajouter."/>
                    }
                    <Form 
                        onSubmit={
                            (event) => {
                                event.preventDefault();
                                ajouter_evenement(
                                    state,
                                    setState,
                                    authContext
                                ) 
                            }
                        } 
                        noValidate
                    >
                        <Form.Group className={styles.section_bouton_selection_image}>
                            <Form.Label>Image de l'événement*</Form.Label>
                            <Button 
                                className={styles.bouton_ajout_image} 
                                variant="primary"
                                onClick={() => {
                                    refInputAjoutImage.current.classList.remove("d-none");
                                    refInputSelectionImage.current.classList.add("d-none");
                                    refChangementPageImage.current.classList.add("d-none");
                                    refAlerteSelectionImage.current.classList.add("d-none");
                                }}
                            >
                                Téléverser une image
                            </Button>
                            <Button 
                                className={styles.bouton_ajout_image} 
                                variant="primary"
                                onClick={() => {
                                    refInputAjoutImage.current.classList.add("d-none");
                                    refInputSelectionImage.current.classList.remove("d-none");
                                    refChangementPageImage.current.classList.remove("d-none");
                                    refAlerteSelectionImage.current.classList.remove("d-none");
                                }}
                            >
                                Sélectionner une image de la bibliothèque
                            </Button>
                            <Button 
                                className={styles.bouton_ajout_image} 
                                variant="primary"
                                onClick={() => {
                                    refInputAjoutImage.current.classList.add("d-none");
                                    refInputSelectionImage.current.classList.add("d-none");
                                    refChangementPageImage.current.classList.add("d-none");
                                    refAlerteSelectionImage.current.classList.add("d-none");
                                }}
                            >
                                Fermer
                            </Button>
                        </Form.Group>
                        <Form.Group ref={refInputAjoutImage} className={"mb-3 d-none " + styles.section_selection_image} controlId="hf_fichier_image_evenement">
                            <Form.Control 
                                onChange={
                                    (event) => 
                                    { 
                                        let blob = URL.createObjectURL(event.target.files[0]);
                                        setState({
                                            ...state,
                                            fichier_image_evenement: blob,
                                            evenement: {
                                                ...state.evenement,
                                                fichier_image: event.target.files[0],
                                                fichier_image_id: null
                                            }
                                        })
                                    }
                                }
                                type="file"
                                accept=".png,.jpeg,.jpg"
                            />
                        </Form.Group>
                        <Form.Group ref={refChangementPageImage} className="d-none">
                            {
                                state.listeImageEvenement &&
                                state.listeImageEvenement.length > 4   &&
                                <Row>
                                    {
                                        state.listeImageEvenement &&
                                        <Col lg="12">
                                            <p>Filtrer par</p>
                                            <Dropdown className={styles.dropdown_categorie}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    { 
                                                        state.choix_categorie ? 
                                                        state.choix_categorie.nom :
                                                        "Catégorie d'images d'événement" 
                                                    }
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        state.listeImageCategorie &&
                                                        state.listeImageCategorie.length > 0 &&
                                                        state.listeImageCategorie.map((categorie_image) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={creerCleUnique()}
                                                                    onClick={() => {
                                                                        setState({
                                                                            ...state,
                                                                            choix_categorie: categorie_image
                                                                        })
                                                                    }}
                                                                >
                                                                    {categorie_image.nom}
                                                                </Dropdown.Item>
                                                            );
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Row>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <Form.Control 
                                                        className={styles.recherche_categorie} 
                                                        type="text" 
                                                        placeholder="Entrer le nom de l'image"
                                                        onChange={(event) => {
                                                            setState({
                                                                ...state,
                                                                nom_image_rechercher: event.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg="4"></Col>
                                            </Row>
                                            
                                        </Col>
                                    }
                                    <Col lg="6" className="text-center">
                                        <Button 
                                            className={styles.bouton_page_image}
                                            disabled={state.imageMin === 0}
                                            onClick={
                                                () => {
                                                    setState({
                                                        ...state,
                                                        imageMin: state.imageMin - 4,
                                                        imageMax: state.imageMax - 4
                                                    })
                                                }
                                            }
                                        >Page précèdente</Button>
                                    </Col>
                                    <Col lg="6" className="text-center">
                                        <Button
                                            className={styles.bouton_page_image}
                                            disabled={state.imageMax >= state.listeImageEvenement.length}
                                            onClick={
                                                () => {
                                                    setState({
                                                        ...state,
                                                        imageMin: state.imageMin + 4,
                                                        imageMax: state.imageMax + 4
                                                    })
                                                }
                                            }
                                        >Page suivante</Button>
                                    </Col>
                                </Row>
                            }
                        </Form.Group>
                        <Form.Group ref={refAlerteSelectionImage}>
                            {
                                state.listeImageEvenement &&
                                state.listeImageEvenement.length === 0 &&
                                <HFAlerte
                                    aucunRow={true}
                                    type="warning"
                                    texte="Aucune image à afficher."
                                />
                            }
                        </Form.Group>
                        <Form.Group ref={refInputSelectionImage} className={"d-none text-center " + styles.section_selection_image} controlId="hf_fichier_selection_image">
                            {
                                state.listeImageEvenement &&
                                state.listeImageEvenement
                                    .filter((image_evenement) => {
                                        if(state.nom_image_rechercher)
                                        {
                                            if(!image_evenement.nom) return false;
                                            return image_evenement.nom.toLowerCase().includes(state.nom_image_rechercher.toLowerCase())
                                        }
                                        return true;
                                    })
                                    .filter((image_evenement) => {
                                        if(state.choix_categorie)
                                        {
                                            let categorie = image_evenement.categories.find((categorie) => {
                                                if(categorie.id === state.choix_categorie.id)
                                                {
                                                    return categorie;
                                                }
                                                return null;
                                            })
                                            if(categorie) return true;
                                            return false;
                                        }
                                        return true;
                                    })
                                    .sort((a,b) => {
                                        return compareAsc(parseISO(b.date_creation), parseISO(a.date_creation));
                                    })
                                    .slice(state.imageMin, state.imageMax)
                                    .map((image) => {
                                        return (
                                            <Col key={creerCleUnique()} xs={"12"} lg="3">
                                                <Card
                                                    id={image.id}
                                                    className={Number(state.evenement.fichier_image_id) !== Number(image.id) ? styles.carte_image_bibliotheque : styles.carte_image_bibliotheque_selectionne}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        refInputAjoutImage.current.classList.add("d-none");
                                                        refInputSelectionImage.current.classList.add("d-none");
                                                        refChangementPageImage.current.classList.add("d-none");
                                                        refAlerteSelectionImage.current.classList.add("d-none");
                                                        setState({
                                                            ...state,
                                                            fichier_image_evenement: CONSTANTS.nomUrlImageApi + image.fichier_image,
                                                            alertFormAjoutEvenement: {
                                                                type: "",
                                                                texte: ""
                                                            },
                                                            evenement: {
                                                                ...state.evenement,
                                                                fichier_image: null,
                                                                fichier_image_id: e.target.parentElement.id ? e.target.parentElement.id : e.target.parentElement.parentElement.id
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Card.Img 
                                                        variant="top" 
                                                        className={styles.image_bibliotheque}
                                                        src={CONSTANTS.nomUrlImageApi + image.fichier_image} 
                                                        alt={image.description}
                                                    />
                                                    <Card.Body>
                                                        <Card.Title>{image.nom ?? "Aucun nom associé"}</Card.Title>
                                                        <Card.Text>{image.description ?? "Aucune description associé"}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })
                            }
                        </Form.Group>
                        {
                            ((state.listeImageEvenement && state.nom_image_rechercher) ||
                            (state.listeImageEvenement && state.choix_categorie)) &&
                                state.listeImageEvenement
                                .filter((image_evenement) => {
                                    if(state.nom_image_rechercher)
                                    {
                                        if(!image_evenement.nom) return false;
                                        return image_evenement.nom.toLowerCase().includes(state.nom_image_rechercher.toLowerCase())
                                    }
                                    return true;
                                })
                                .filter((image_evenement) => {
                                    if(state.choix_categorie)
                                    {
                                        let categorie = image_evenement.categories.find((categorie) => {
                                            if(categorie.id === state.choix_categorie.id)
                                            {
                                                return categorie;
                                            }
                                            return null;
                                        })
                                        if(categorie) return true;
                                        return false;
                                    }
                                    return true;
                                })
                                .length === 0 &&
                                <HFAlerte
                                    aucunRow={true}
                                    type="warning"
                                    texte="Aucune image associé à votre recherche."
                                />
                        }
                        <Form.Group className="mb-3" controlId="hf_nom_evenement">
                            <Form.Label>Nom de l'événement*</Form.Label>
                            <Form.Control 
                                onChange={
                                    (event) => 
                                    { 
                                        setState({
                                            ...state,
                                            alertFormAjoutEvenement: {
                                                type: "",
                                                texte: ""
                                            },
                                            evenement: {...state.evenement, nom: event.target.value }, 
                                        })
                                    }
                                } 
                                type="text" 
                                placeholder="Festival Été de Québec" 
                                value={state.evenement.nom} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_adresse_evenement">
                            <Form.Label>Adresse de l'événement*</Form.Label>
                            <Form.Control 
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        alertFormAjoutEvenement: {
                                            type: "",
                                            texte: ""
                                        },
                                        evenement: {
                                            ...state.evenement,
                                            adresse: event.target.value
                                        },
                                        listeResultatMap: []
                                    })
                                }} 
                                type="text" 
                                value={state.evenement.adresse} 
                                placeholder="1, Rue Bidon, Montréal, Québec G6L 5TG" 
                            />
                            <ListGroup>
                                {
                                    state.listeResultatMap && 
                                    state.listeResultatMap.length > 0 && 
                                    state.listeResultatMap
                                    .slice(0,5)
                                    .map((resultat) => {
                                        if(resultat.valeur === state.evenement.adresse) return;
                                        return (
                                            <ListGroup.Item key={creerCleUnique()} onClick={(event) => selectionner_adresse(event, state, setState)}>
                                                {resultat.valeur}
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_date_heure_depart_evenement">
                            <Form.Label>Date/heure de départ de l'événement*</Form.Label>
                            <input 
                                className='form-control'
                                type='datetime-local'
                                min={new Date()}
                                max={"9999-12-31 00:00:00"}
                                value={state.evenement.date_depart ?? ""}
                                step="900"
                                onChange={
                                    (event) => 
                                    { 
                                        setState({ 
                                            ...state,
                                            alertFormAjoutEvenement: {
                                                type: "",
                                                texte: ""
                                            },
                                            evenement: {
                                                ...state.evenement,
                                                date_depart: event.target.value
                                            }
                                        })
                                    }
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_date_heure_fin_evenement">
                            <Form.Label>Date/Heure de fin de l'événement</Form.Label>
                            <input 
                                className='form-control'
                                type='datetime-local'
                                value={state.evenement.date_fin ?? ""}
                                min={new Date()}
                                max={"9999-12-31 00:00:00"}
                                step="900"
                                onChange={
                                    (event) => 
                                    { 
                                        setState({ 
                                            ...state,
                                            alertFormAjoutEvenement: {
                                                type: "",
                                                texte: ""
                                            },
                                            evenement: {
                                                ...state.evenement,
                                                date_fin: event.target.value === '' ? null : event.target.value
                                            }
                                        })
                                    }
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_nom_evenement">
                            <Form.Label>Lien vers la billeterie</Form.Label>
                            <Form.Control 
                                onChange={
                                    (event) => 
                                    { 
                                        setState({
                                            ...state,
                                            alertFormAjoutEvenement: {
                                                type: "",
                                                texte: ""
                                            },
                                            evenement: {...state.evenement, lien_billeterie: event.target.value }, 
                                        })
                                    }
                                } 
                                type="text" 
                                placeholder="https://www.eventbrite.ca/URL_XYZ" 
                                value={state.evenement.lien_billeterie} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_description_evenement">
                            <Form.Label>Description de l'événement*</Form.Label>
                            <Form.Control
                                placeholder="ex: Spectacle de de différents artistes sur les plaines d'Abraham."
                                className={styles.description_evenement}
                                onChange={
                                    (event) => 
                                    { 
                                        setState({ 
                                            ...state,
                                            alertFormAjoutEvenement: {
                                                type: "",
                                                texte: ""
                                            },
                                            evenement: {
                                                ...state.evenement,
                                                description: event.target.value
                                            }
                                        })
                                    }
                                } 
                                value={state.evenement.description} 
                                as="textarea" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_liste_categorie_evenement">
                            <Form.Label>Catégorie associé à l'événement*</Form.Label>
                            {state.listeCategories && state.listeCategories.map((categorie) => (
                                <div key={`default-checkbox-${categorie.id}`}>
                                    <Form.Check
                                        onClick={(event) => ajouter_categorie(event, state, setState)}
                                        type="checkbox"
                                        value={categorie.id}
                                        selected={true}
                                        id={`default-${categorie.id}`}
                                        label={`${categorie.nom}`}
                                    />
                                </div>
                            ))}
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Ajouter l'événement
                        </Button>
                    </Form>
                </Col>
                <Col lg="3" xs="1"></Col>
            </Row>
        </>
    );
  }
import React, { useContext, useState } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import TitrePage from '../composants/commun/titre-page';
import IconeChargement from '../composants/commun/icone-chargement';
import FicheUtilisateur from '../composants/voir-utilisateur/fiche-utilisateur';
import { AuthContext } from '../contexts/auth-context';

export default function PageVoirUtilisateur() {
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Voir un événement'
      }
    });
    const authContext = useContext(AuthContext);
  

    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          {
            !authContext.authData.estConnecte &&
            <>
              <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
              <IconeChargement/>
            </>
          }
          {
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            <>
              <FicheUtilisateur></FicheUtilisateur>
            </>
          }
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }
import { createContext, useEffect, useState } from "react";
import { obtenirProfil } from "../services/profilService";
import { verifier_token } from "../services/connexionService";
import { obtenirValeur, sauvegarderValeur, supprimerValeur } from "../utilitaires/localStorageUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../config/constants";

// Create a context with default values
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [authData, setAuth] = useState({
    token: null,
    lastPulledProfile: null,
    profil: null,
    estConnecte: false
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setAuth({
      ...authData,
      profil: authData.lastPulledProfile
    })
  }, [authData.lastPulledProfile])

  const disconnect = () => {
    supprimerValeur("token")
    setAuth({
      token: null,
      profil: null,
      estConnecte: false
    })
    if(location.pathname !== "/connexion" && location.pathname !== "/mot-de-passe-oublier")
    {
      navigate("/connexion")
    }
  }

  const reloadProfile = () => {
    obtenirProfil(authData.token, authData, setAuth)
  }

  const returnToHomePage = () => {
    navigate("/")
  }

  useEffect(() => {
    if(authData.token)
    {
      if(!authData.profil)
      {
        obtenirProfil(authData.token, authData, setAuth)
      }
      sauvegarderValeur("token", authData.token)
    }
    else
    {
      let tokenInStorage = obtenirValeur("token");
      if(!tokenInStorage) return;
      setAuth({
        ...authData,
        estConnecte: true,
        token: tokenInStorage
      })
    }
  }, [authData.token])

  useEffect(() => {
    let intervalToCheckToken

    intervalToCheckToken = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, authData, disconnect, returnToHomePage, location)
    
    return () => {
      clearInterval(intervalToCheckToken)
    }
  }, [authData])

  return (
    <AuthContext.Provider value={{ authData, setAuth, reloadProfile, disconnect, returnToHomePage }}>
      {children}
    </AuthContext.Provider>
  );
};
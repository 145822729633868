import React, { useContext, useState } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { useParams } from 'react-router-dom';
import ListeEvenementParCategorie from '../composants/liste-evenement/liste-evenement-par-categorie';
import IconeChargement from '../composants/commun/icone-chargement';
import { AuthContext } from '../contexts/auth-context';

export default function PageVoirEvenementParCategorie() {
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Voir des événements par catégorie',
      }
    });
    const params = useParams()
    const authContext = useContext(AuthContext);

    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          {
            !authContext.authData.estConnecte &&
            <>
              <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
              <IconeChargement/>
            </>
          }
          {
            authContext.authData.estConnecte &&
            authContext.authData.profil &&
            <>
              <TitrePage titre_page={"Voir tous les événements de la catégorie '" + params.categorie_slug + "'"}></TitrePage>
              <ListeEvenementParCategorie categorie_uuid={ params.categorie_slug}></ListeEvenementParCategorie>
            </>
          }
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }